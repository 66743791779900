import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => {

    const {formatCurrency, currencyToWord} = handlePurchase();
    const exportToPDF = (company, invoice, barcode, userName) => {
        const tableItems = invoice.has_item_detail ? invoice.general : invoice.ledgers;
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 70 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(invoice, tableItems, barcode),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10
            },
            info: {
                title: invoice.bill_number
            }
        }

        pushIntoTable(doc, invoice, tableItems)

            const pdfDocGenerator = pdfMake.createPdf(doc);
            pdfDocGenerator.open();
    }
    const formatDate = (date) => {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

            return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
            return {
                margin: [ 60, 10, 60, 10 ],
                columns: [
                    {
                        alignment: 'left',
                        image: company.logo64,
                        maxHeight: 60,
                        maxWidth: 60
                    },

                    {
                        alignment: 'right',
                        stack: [
                            company.name,
                            'Address: ' + company.address,
                            'Phone: ' + company.phone,
                            'E-mail: ' + company.email
                        ]
                    }
                ]

            }
    }
    const getContent = (invoice, tableItems, barcode) => {
            return  [
                {
                    text: 'INVOICE',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 20, 10],
                    bold: true,
                    color: 'black',
                },

                {
                    margin: [0, 20, 0, 0],
                    alignment: 'justify',
                    columns: [
                        {
                            width: '50%',
                            stack: [
                                {
                                    text: 'INVOICE TO',
                                    color: 'black',
                                    bold: true,

                                },

                                {
                                    text: invoice.contact.name,
                                    alignment: 'left',
                                    width: '50%'
                                },

                                {
                                    text: invoice.contact.profile_items[0] ? invoice.contact.profile_items[0].value : ''
                                }
                            ]

                        },

                        {
                            alignment: 'right',
                            stack: [
                                {
                                    text: [
                                        {
                                            text: 'DATE: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: formatToDdMmYy(invoice.date),
                                        }
                                     ]
                                },

                                {
                                    text: [
                                        {
                                            text: 'INVOICE REF',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },
                                     ]
                                },

                                {
                                    svg: barcode
                                }

                            ]
                        },
                    ]
                },

                {
                    style: 'tableExample',
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: []
                    },
                    layout: {
				        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#f3f2f7' : null;
                        },
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        }
			        }
		        },

                {
                    columns: [
                        {
                            width: '65%',
                            text: [
                                {
                                    text: 'In Words: ',
                                    bold: true
                                },

                                {
                                    text: invoice.total_amount ? currencyToWord(invoice.total_amount) : ''
                                }
                            ]
                        },

                        {
                            table: {
                                widths: ['*', '*'],
                                alignment: 'right',
                                body: [
                                    [{ text: 'Sub Total' }, { text: formatCurrency(invoice.subtotal_amount), alignment: 'right'}],
                                    [ { text: 'VAT' }, {text:  formatCurrency(invoice.vat_amount), alignment: 'right' } ],
                                    [ { text: 'Total', bold: true }, { text: formatCurrency(invoice.total_amount), alignment: 'right'}]
                                ]
                            },
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0.5;
                                },
                                vLineWidth: function (i, node) {
                                    return 0.5;
                                }
                            }
                        }
                    ],
                    margin: [0, 10]
                },

                {
                    alignment: 'justify',
                    text: [{ text: invoice.description ? 'NOTE: \n' : '', bold: true}, {text: invoice.description} ]
                }

            ]
    }
    const getFooter = (userName) =>{
            return {
                margin: [ 60, 0, 60, 0 ],
                columns: [
                    {
                        columns: [
                            {
                                width: '90%',
                                alignment: 'left',
                                text: 'N.B: This is a system generated documents and requires no manual signature.',
                                margin: [0, 50, 0, 0],
                                fontSize: 8
                            }
                        ]

                    },

                    {
                        width: '20%',
                        alignment: 'right',
                        fontSize: 8,
                        margin: [0, 33, 0, 0],
                        stack: [
                            {
                                text: 'Printed by',
                                bold: true
                            },
                            {
                                text: userName
                            },
                            {
                                text: formatDate(new Date())
                            }
                        ]
                    }
                ]
            }
    }
    const pushIntoTable = (doc, invoice, tableItems) => {
            var flag = false;
            tableItems.forEach(item => {
                if( !flag ){
                    const tableHeader = getTableHeader();
                    doc.content[2].table.body.push(tableHeader);
                    flag = true;
                }
                const rowData = getRowData(invoice, item);
                return doc.content[2].table.body.push(rowData);
            });
    }
    const getTableHeader = () => {
            return [
                { text: 'PARTICULARS' },
                { text: 'QTY' },
                { text: 'RATE' },
                { text: 'AMOUNT', alignment: 'right' },
                { text: 'VAT RATE', alignment: 'right' },
                { text: 'VAT AMOUNT', alignment: 'right' },
                { text: 'TOTAL AMOUNT', alignment: 'right' }
            ]
    }
    const getRowData = (invoice, item) => {
            let finaTotalAmount = item.total_amount
            if (!invoice.has_item_detail){
                finaTotalAmount = item.credit - item.discount_amount + item.vat_amount
            }
            return [
                { text: (invoice.has_item_detail ? item.product.name : item.head.name) + '\n' + (item.description ? item.description : '') },
                { text:  invoice.has_item_detail ? formatCurrency(item.quantity) : '', alignment: 'right' },
                { text: invoice.has_item_detail ? formatCurrency(item.rate) : '', alignment: 'right' },
                { text: formatCurrency(item.amount), alignment: 'right' },
                { text: formatCurrency(item.vat), alignment: 'right' },
                { text: formatCurrency(item.vat_amount), alignment: 'right' },
                { text: formatCurrency(finaTotalAmount), alignment: 'right' }
            ];
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
