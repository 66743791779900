import Network from '@/services/network'
import {ref} from 'vue'

export default function handleBarcode() {
    const network = new Network;
    const loading = ref(false)

    const fetchBarcode = (query) => {
        return network.api('get', '/get/barcode' + query);
    }

    return {
        fetchBarcode,
        loading
    }
}
